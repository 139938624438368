<template>
  <div>
    <div class="zan">
      <div class="zanbox_l">
        <div class="zanbox_ls">
          <icon-svg icon-class="iconfenxiang" />
          <span>分享</span>
          <el-popover
            placement="bottom"
            title="请使用微信扫描二维码"
            width="200"
            trigger="click"
          >
            <div id="qrcode" ref="qrcode"></div>
            <icon-svg
              slot="reference"
              icon-class="iconweixin"
              @click="wxfenxiang"
            />
          </el-popover>
        </div>
      </div>
      <div class="zanbox_c">
        <div class="zanbox_ctop" @click="dzclick">
          <div>
            <icon-svg v-if="zan_t" icon-class="icongood2" />
            <icon-svg v-if="zan_f" icon-class="icongood" />
          </div>
          <div>{{ zannum }}人点赞</div>
        </div>
        <ul class="zanbox_cbtm">
          <li v-for="(item, index) in zanlist" :key="index">
            <img :src="item.userpic" alt="" />
          </li>
        </ul>
      </div>
      <div class="zanbox_r" :style="scstyle" @click="scclick">
        <icon-svg
          style="color: #fff"
          v-show="sctype"
          icon-class="iconCollection"
        />
        <icon-svg style="color: #333" v-show="sctype_f" icon-class="iconadd" />
        <span :style="scstyle">{{ sctext }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  props: ["all"],
  data() {
    return {
      scstyle: "color:#333;",
      userinfo: {},
      sctext: "收藏",
      zanlist: [],
      zannum: 0,
      zan_t: false,
      zan_f: false,
      sctype: false,
      sctype_f: false,
    };
  },
  created() {
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    if (this.userinfo != null) {
      this.sc();
      this.dz();
    } else {
      this.zan_f = true;
      this.sctext = "收藏";
      this.sctype_f = true;
      this.scstyle = "color:#333;background:white";
    }

    this.dzlist();
  },
  methods: {
    sc() {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/userYesCollect",
        method: "post",
        data: {
          classid: that.all.classid,
          id: that.all.id,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.sctext = "已收藏";
          that.sctype = true;
          that.sctype_f = false;
          that.scstyle = "color:white;background:rgb(47,90,255);";
        } else {
          that.sctext = "收藏";
          that.sctype = false;
          that.sctype_f = true;
          that.scstyle = "color:#333;background:white";
        }
      });
    },
    dz() {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/userYesLike",
        method: "post",
        data: {
          classid: that.all.classid,
          id: that.all.id,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.zan_t = true;
          that.$forceUpdate();
        } else {
          that.zan_f = true;
          that.$forceUpdate();
        }
      });
    },
    dzclick() {
      var that = this;
      if (that.userinfo != null) {
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/userLike",
          method: "post",
          data: {
            classid: that.all.classid,
            id: that.all.id,
          },
        }).then((res) => {
          if (res.data.code == 1) {
            if (res.data.messages == "取消点赞成功") {
              // this.$message(res.data.messages);
              that.zan_t = false;
              that.zan_f = true;
              that.dzlist();
              that.$forceUpdate();
            } else {
              // this.$message(res.data.messages);
              that.zan_t = true;
              that.zan_f = false;
              that.dzlist();
              that.$forceUpdate();
            }
          } else {
          }
        });
      } else {
        this.$router.push({ path: "/Sjlogin" });
      }
    },
    dzlist() {
      var that = this;
      that.zanlist = [];
      that.zannum = 0;
      this.$axios({
        url: "/api/likeList",
        method: "post",
        data: {
          classid: that.all.classid,
          id: that.all.id,
        },
      }).then((res) => {
        if (res.data.code == 1) {
          that.zanlist = res.data.result.list;
          that.zannum = res.data.result.count;
          that.$forceUpdate();
        } else {
        }
      });
    },
    scclick() {
      var that = this;
      if (that.userinfo != null) {
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/userCollect",
          method: "post",
          data: {
            classid: that.all.classid,
            id: that.all.id,
          },
        }).then((res) => {
          if (res.data.code == 1) {
            if (res.data.messages == "收藏成功") {
              // this.$message(res.data.messages);
              that.sctext = "已收藏";
              that.scstyle = "color:white;background:rgb(47,90,255);";
              that.sctype = true;
              that.sctype_f = false;
              that.$forceUpdate();
            } else {
              // this.$message(res.data.messages);
              that.sctext = "收藏";
              that.scstyle = "color:#333;background:white";
              that.sctype = false;
              that.sctype_f = true;
              that.$forceUpdate();
            }
          }
        });
      } else {
        this.$router.push({ path: "/Sjlogin" });
      }
    },
    wxfenxiang() {
      var url = window.top.document.URL;
      console.log(url);
      let qrcode = new QRCode("qrcode", {
        width: 200, // 二维码宽度，单位像素
        height: 200, // 二维码高度，单位像素
        text: url,
      });
    },
  },
  mounted() {
    this.wxfenxiang();
  },
};
</script>
<style>
.el-popover__title {
  text-align: center;
}
</style>