<template>
  <div class="Sjpinglun" style="width: 100%; overflow: hidden">
    <div class="Sjpingluntop">评论</div>
    <div v-clickoutside="hideReplyBtn" @click="inputFocus" class="my-reply">
      <img
        class="header-img"
        :style="headerimgsize"
        :size="40"
        :src="myHeader"
      />
      <div class="reply-info">
        <div
          tabindex="0"
          contenteditable="true"
          id="replyInput"
          spellcheck="false"
          :placeholder="placeholderbig"
          class="reply-input"
          @focus="showReplyBtn"
          @input="onDivInput($event)"
        ></div>
      </div>
      <div class="reply-btn-box" v-show="btnShow">
        <el-button
          class="reply-btn"
          size="medium"
          @click="sendComment"
          type="primary"
          >发表评论</el-button
        >
      </div>
    </div>
    <div
      v-for="(item, i) in comments"
      :key="i"
      class="author-title reply-father"
    >
      <img class="header-img" :size="40" :src="item.userpic" />
      <div class="author-info">
        <span class="author-name">{{ item.username }}</span>
        <span class="author-time">{{ item.create_time }}</span>
      </div>
      <div class="icon-btn">
        <span @click="showReplyInput(i, item.username, item.userid, item.cid)"
          ><i class="iconfont el-icon-s-comment"></i>回复</span
        >
        <span
          v-if="item.userid == myId ? true : false"
          @click="shanchu(item.cid)"
          ><i class="iconfont el-icon-delete-solid"></i>删除</span
        >
      </div>
      <div class="talk-box">
        <p>
          <span class="reply" v-html="item.saytext"></span>
        </p>
      </div>
      <div class="reply-box">
        <div v-for="(reply, j) in item.list" :key="j" class="author-title">
          <img class="header-img" :size="40" :src="reply.userpic" />
          <div class="author-info">
            <span class="author-name">{{ reply.username }}</span>
            <span class="author-time">{{ reply.create_time }}</span>
          </div>
          <div class="icon-btn">
            <span
              @click="
                showReplyInputsmall(i, reply.username, item.cid, reply.userid)
              "
              ><i class="iconfont el-icon-s-comment"></i>回复</span
            >
            <span
              v-if="reply.userid == myId ? true : false"
              @click="shanchu(reply.cid)"
              ><i class="iconfont el-icon-delete-solid"></i>删除</span
            >
          </div>
          <div class="talk-box">
            <p>
              <span>回复 {{ reply.replay_name }}:</span>
              <span class="reply" v-html="reply.saytext"></span>
            </p>
          </div>
          <div class="reply-box"></div>
        </div>
      </div>
      <div v-show="_inputShow(i)" class="my-reply my-comment-reply">
        <img class="header-img" :size="40" :src="myHeader" />
        <div class="reply-info">
          <div
            tabindex="0"
            contenteditable="true"
            spellcheck="false"
            :placeholder="huifuname"
            @input="onDivInput($event)"
            class="reply-input reply-comment-input"
          ></div>
        </div>
        <div class="reply-btn-box">
          <el-button
            class="reply-btn"
            size="medium"
            @click="sendCommentReply(i, j)"
            type="primary"
            >发表评论</el-button
          >
        </div>
      </div>
    </div>
    <el-pagination
      :pager-count="5"
        @current-change="currentchange"
        :page-size="pagesize"
        background
        layout="prev, pager, next"
        :total="total"
      >
      </el-pagination>
  </div>
</template>
<script>
const clickoutside = {
  // 初始化指令
  bind(el, binding, vnode) {
    function documentHandler(e) {
      // 这里判断点击的元素是否是本身，是本身，则返回
      if (el.contains(e.target)) {
        return false;
      }
      // 判断指令中是否绑定了函数
      if (binding.expression) {
        // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
        binding.value(e);
      }
    }
    // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
    el.vueClickOutside = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  update() {},
  unbind(el, binding) {
    // 解除事件监听
    document.removeEventListener("click", el.vueClickOutside);
    delete el.vueClickOutside;
  },
};
export default {
  name: "ArticleComment",
  props: ["all"],
  data() {
    return {
      pagesize:6,
      headerimgsize: "",
      huifuname: "",
      btnShow: false,
      placeholderbig: "",
      index: "0",
      replyComment: "",
      myName: "",
      myHeader:
        "https://dss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1736163552,1696885273&fm=26&gp=0.jpg",
      myId: "",
      to: "",
      toId: -1,
      comments: [],
      floorid: "",
      total:0,
      userinfo: {},
    };
  },
  directives: { clickoutside },
  methods: {
    currentchange(index) {
      this.plaxios(index);
    },
    inputFocus() {
      var replyInput = document.getElementById("replyInput");
      replyInput.style.padding = "8px 8px";
      replyInput.style.border = "2px solid blue";
      replyInput.focus();
    },
    showReplyBtn() {
      this.btnShow = true;
    },
    hideReplyBtn() {
      this.btnShow = false;
      replyInput.style.padding = "10px";
      replyInput.style.border = "none";
    },
    showReplyInput(i, name, id, floorid) {
      if (this.userinfo != null) {
        this.comments[this.index].inputShow = false;
        this.index = i;
        this.comments[i].inputShow = true;
        this.to = name;
        this.toId = id;
        this.huifuname = "回复" + name + ":";
        this.floorid = floorid;
      } else {
        this.$router.push({ path: "/Sjlogin" });
      }
    },
    showReplyInputsmall(i, name, id, floorid) {
      if (this.userinfo != null) {
        this.comments[this.index].inputShow = false;
        this.index = i;
        this.comments[i].inputShow = true;
        this.to = name;
        this.toId = floorid;
        this.floorid = id;
        this.huifuname = "回复" + name + ":";
      } else {
        this.$router.push({ path: "/Sjlogin" });
      }
    },
    _inputShow(i) {
      return this.comments[i].inputShow;
    },
    sendComment() {
      var that = this;
      if (this.userinfo != null) {
        if (!this.replyComment) {
          this.$message({
            showClose: true,
            type: "warning",
            message: "评论不能为空",
          });
        } else {
          let a = {};
          let input = document.getElementById("replyInput");
          let timeNow = new Date().getTime();
          let time = this.dateStr(timeNow);
          a.username = this.myName;
          a.saytext = this.replyComment;
          a.userpic = this.myHeader;
          a.create_time = time;
          this.comments.push(a);
          this.$axios({
            headers: { "X-CSRF-TOKEN": that.userinfo.token },
            url: "/api/comments/addCommon",
            method: "post",
            data: {
              id: that.all.id,
              classid: that.all.classid,
              userid: that.myId,
              saytext: that.replyComment,
              replyid: 0,
              floor: 0,
            },
          })
            .then((res) => {
              if (res.data.code == 1) {
                that.$message("评论成功");
                location.reload();
              }
            })
            .catch((res) => {
              that.$message("评论成功");
              location.reload();
            });
          this.replyComment = "";
          input.innerHTML = "";
        }
      } else {
        this.$router.push({ path: "/Sjlogin" });
      }
    },
    sendCommentReply(i, j) {
      var that = this;
      if (!this.replyComment) {
        this.$message({
          showClose: true,
          type: "warning",
          message: "评论不能为空",
        });
      } else {
        let a = {};
        let timeNow = new Date().getTime();
        let time = this.dateStr(timeNow);
        a.username = this.myName;
        a.replay_name = this.to;
        a.replay_userpic = this.myHeader;
        a.saytext = this.replyComment;
        a.create_time = time;
        this.comments[i].list.push(a);
        this.$axios({
          headers: { "X-CSRF-TOKEN": that.userinfo.token },
          url: "/api/comments/addCommon",
          method: "post",
          data: {
            id: that.all.id,
            classid: that.all.classid,
            userid: that.myId, //当前登录用户id
            saytext: that.replyComment,
            replyid: that.toId, //回复用户id（默认为0）
            floor: that.floorid, //楼层id
          },
        })
          .then((res) => {
            if (res.data.code == 1) {
              that.$message("评论成功");
              location.reload();
            } else {
              that.$message("评论成功");
              location.reload();
            }
          })
          .catch((res) => {
            that.$message("评论成功");
            location.reload();
          });
        this.replyComment = "";
        document.getElementsByClassName("reply-comment-input")[i].innerHTML =
          "";
      }
    },
    onDivInput: function (e) {
      this.replyComment = e.target.innerHTML;
    },
    dateStr(date) {
      //获取js 时间戳
      var time = new Date().getTime();
      //去掉 js 时间戳后三位，与php 时间戳保持一致
      time = parseInt((time - date) / 1000);
      //存储转换值
      var s;
      if (time < 60 * 10) {
        //十分钟内
        return "刚刚";
      } else if (time < 60 * 60 && time >= 60 * 10) {
        //超过十分钟少于1小时
        s = Math.floor(time / 60);
        return s + "分钟前";
      } else if (time < 60 * 60 * 24 && time >= 60 * 60) {
        //超过1小时少于24小时
        s = Math.floor(time / 60 / 60);
        return s + "小时前";
      } else if (time < 60 * 60 * 24 * 30 && time >= 60 * 60 * 24) {
        //超过1天少于30天内
        s = Math.floor(time / 60 / 60 / 24);
        return s + "天前";
      } else {
        //超过30天ddd
        var date = new Date(parseInt(date));
        return (
          date.getFullYear() +
          "/" +
          (date.getMonth() + 1) +
          "/" +
          date.getDate()
        );
      }
    },
    shanchu(cid) {
      var that = this;
      this.$axios({
        headers: { "X-CSRF-TOKEN": that.userinfo.token },
        url: "/api/comments/deleteCommon",
        method: "post",
        data: {
          cid,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.data.code == 1) {
            that.$message("删除评论成功");
            location.reload();
          }
        })
        .catch((res) => {
          that.$message("删除评论成功");
          location.reload();
        });
    },
    plaxios(page){
      var that=this;
      this.$axios({
      url: "/api/commentList",
      method: "post",
      data: {
        id: that.all.id,
        classid: that.all.classid,
        page,
        limit: that.pagesize,
      },
    }).then((res) => {
      if (res.data.code == 1) {
        that.total=res.data.result.count;
        that.comments = res.data.result.list;
      }
    });
    }
  },
  created() {
    var that = this;
    var userinfo = JSON.parse(localStorage.getItem("userinfo"));
this.tokentimeyz();
    this.userinfo = userinfo;
    if (userinfo != null) {
      this.placeholderbig = "请输入评论";
      that.myName = userinfo.username;
      that.myHeader = userinfo.userpic;
      that.myId = userinfo.userid;
      that.headerimgsize = "width:30px;height:30px;";
    } else {
      this.placeholderbig = "登录后可进行评论！";
    };
    that.plaxios(1);
  },
};
</script>
<style>
.my-reply {
  padding: 10px;
  padding: 2rem;
  box-sizing: border-box;
}
.my-reply .header-img {
  display: inline-block;
  vertical-align: top;
  width: 5%;
  height: 5%;
  max-width: 30px;
  max-height: 30px;
  border-radius: 50%;
  margin-top: 5px;
}
.my-reply .reply-info {
  display: inline-block;
  margin-left: 5px;
  width: 94%;
}
.my-reply .reply-info .reply-input {
  min-height: 20px;
  line-height: 20px;
  color: #666;
  background-color: #eee;
  border-radius: 5px;
  padding: 8px;
}
.my-reply .reply-info .reply-input:empty:before {
  content: attr(placeholder);
}
.my-reply .reply-info .reply-input:focus:before {
  content: none;
}
.my-reply .reply-info .reply-input:focus {
  padding: 8px 8px;
  border: 2px solid #eee !important;
  box-shadow: none;
  outline: none;
}
.my-reply .reply-btn-box {
  height: 25px;
  margin: 10px 0;
}
.my-reply .reply-btn-box .reply-btn {
  position: relative;
  float: right;
  margin-right: 15px;
}
.my-comment-reply {
  margin-left: 50px;
}
.my-comment-reply .reply-input {
  width: flex;
}
.author-title:not(:last-child) {
  border-bottom: 1px solid rgb(226, 226, 226);
}
.author-title {
  padding:1rem 2rem;
  box-sizing: border-box;
}
.author-title .header-img {
  display: inline-block;
  vertical-align: top;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-top: 5px;
}
.author-title .author-info {
  display: inline-block;
  margin-left: 5px;
  width: 60%;
  height: 40px;
  line-height: 20px;
}
.author-title .author-info > span {
  display: block;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.author-title .author-info .author-name {
  color: #000;
  font-size: 16px;
}
.author-title .author-info .author-time {
  font-size: 12px;
  color: rgb(153 153 153);
}
.author-title .icon-btn {
  width: 20%;
  padding: 0 !important;
  float: right;
  display: flex;
  justify-content: flex-end;
}
.author-title .icon-btn > span {
  cursor: pointer;
}
.author-title .icon-btn .iconfont {
  margin: 0 5px;
}
.author-title .talk-box {
  margin: 0 50px;
}
.author-title .talk-box > p {
  margin: 0;
}
.author-title .talk-box .reply {
  font-size: 16px;
  color: #000;
}
.author-title .reply-box {
  margin: 10px 0 0 50px;
  background-color: #eee;
  border-radius: 1rem;
}
@media (max-width: 800px) {
  .my-reply .reply-info {
    width: 85%;
  }
  .my-reply .reply-info .reply-input {
    min-height: 30px;
    line-height: 30px;
    padding: 0 !important;
  }
  .my-reply .reply-info .reply-input:empty:before {
    /* WebKit browsers */
    color: #666;
    font-size: 1rem !important;
    padding-left: 10px;
  }
  .author-title .icon-btn{
    font-size:1rem;
    width:30%;
    padding-right:20px  !important;
  }
  .author-title .author-info{
    width:50%;
  }
  .Sjpingluntop{
    line-height: 3rem;
    font-size:1rem;
  }
}
</style>




